import * as Dayjs from 'dayjs'
import Button from 'atoms/button';
import StarRating from 'atoms/star-rating';
import handleHrefClick from 'lib/handle-href-click';

export default function ReviewsFold({ 
    background = false,
    compensateForBackground = false,
    noSkew = false,
    reviews,
    summary = {
      averageScore: 4.6,
      reviewCount: 354,
      maxScore: 5,
    }
}) {
  return (
    <div className={`fold-two ${background ? 'background-gradient' : ''} ${noSkew ? 'no-skew' : ''} ${compensateForBackground ? 'compensated' : ''}`}>
      <div className="container">
        <div className="row testimonials">
          <div className="col-sm-12 col-lg-4 text-center text-md-left">
            <h2>Onze reviews</h2>
            <h3 className="text-purple-1 testimonials-row-description">Via de Feedback Company</h3>
            <p>Bij welke fysiotherapeut je ook terecht komt, wij bieden jou de hoogste service en kwaliteit. Wij garanderen je altijd een fysiotherapie in de buurt die voldoet aan onze kwaliteitsnorm.</p>
            <p>
              <span className="score">{summary.averageScore}</span> / {summary.maxScore}
              {' '}
              <span className="review-amount text-purple-1">
                <a onClick={handleHrefClick} href="https://www.feedbackcompany.com/nl-nl/reviews/fysiotherapie4all/">uit {summary.reviewCount} reviews</a>
              </span>
            </p>
          </div>
          <div className="col-sm-12 col-lg-8">
            <div className="row">
              {reviews?.map(review => {
                const reviewText = review.questions.find(question => question.type === 'main_open')?.value;
                const maxWords = 45;
                const body = reviewText.split(' ').length > maxWords ? `${reviewText.split(' ').slice(0, maxWords).join(' ')} [...]` : reviewText

                return (
                  <div key={review.id} className="col-sm-12 col-md-4 mb-4 review">
                    <div className="box text-center background-white rounded-corner">
                      <h4><strong>{review.client.name}</strong></h4>
                      <div className="review-score">
                        <span>
                          <StarRating score={review.total_score} maxScore={parseInt(summary.maxScore)} />
                        </span>
                        &nbsp;&nbsp;
                        <span className="text-purple-2">
                          {review.total_score} / {summary.maxScore}
                        </span>
                        <p className="text-muted review-date">{Dayjs.unix(review.publish_date).format('DD-MM-YY')}</p>
                      </div>
                      <p>{body}</p>
                      <Button link={`https://www.feedbackcompany.com/nl-nl/review/fysiotherapie4all/${review.id}/`} className="align-bottom">Lees meer</Button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .background-gradient {
          background-color: transparent;
          background-image: linear-gradient(120deg, #F5F5F5 0%, #F5F5F5 100%);
          //padding-bottom: 130px;
          //transform: skewY(5deg);
          //transform-origin: top left;
          position: relative;
          z-index: 0;
        }

        .background-gradient.no-skew {
          transform: inherit;
          padding-top: 2.5rem;
          padding-bottom: 2rem;
        }

        .background-gradient.compensated {
          //margin-top: 200px;
          //transform: skewY(5deg) translateY(-200px);
        }

        .background-gradient:not(.no-skew) > .container {
          //transform: skewY(-5deg);
        }

        // .background-gradient .testimonials-row-description {
        //   color: #ffffff;
        // }

        .score {
          color: #102E4A;
          font-weight: 700;
          font-size: 40px;
        }

        .review-amount {
          font-size: 22px;
        }

        .review-date {
          font-size: 0.75rem;
        }

        @media screen and (max-width: 543px) {
          .fold-two {
              /*margin-top: -50px;*/
          }

          .background-gradient.compensated {
            //transform: skewY(5deg) translateY(70px);
          }
        }

        @media screen and (min-width: 544px) {
          .fold-two {
              padding-top: 40px;
          }

          .background-gradient {
            //padding-top: 100px; 
          }
        }

        @media screen and (min-width: 544px) and (max-width: 991px) {
          .review {
              padding: 0 5px !important;
          }
        }
      `}</style>
    </div>
  )
}
