import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons'

export default function StarRating({
  score,
  maxScore = 5,
}) {
  const scoreArray = []
  for (let index = 0; index < maxScore; index++) {
    const leftoverScore = score - index;
    if (leftoverScore >= 1) {
      scoreArray.push('full')
    } else if (leftoverScore <= 0) {
      scoreArray.push('empty')
    } else {
      scoreArray.push('half')
    }
  }

  return (
    <>
      {scoreArray.map((score, index) => {
        switch (score) {
          case 'empty':
            return <FontAwesomeIcon key={`review-start-${index}`} icon={faStarRegular} color="orange" className="star-element" />
          case 'half':
            return <FontAwesomeIcon key={`review-start-${index}`} icon={faStarHalfAlt} color="orange" className="star-element" />
          default:
            return <FontAwesomeIcon key={`review-start-${index}`} icon={faStar} color="orange" className="star-element" />
        }
      })}

      <style global jsx>{`
        .star-element {
          height: 1.1rem;
        }
      `}</style>
    </>
  )
}
